import React ,{Component} from "react";

import {  Table  } from 'semantic-ui-react'


class Vehicles  extends Component {


    mountRows( vehicles) {
        let rows = [];
            if(vehicles !== undefined)
            vehicles.forEach((item, i) => {
                rows.push(
                    <Table.Row key={item.id}>
                    <Table.Cell> {item.model}</Table.Cell>
                    <Table.Cell>{item.color}</Table.Cell>
                    <Table.Cell>{item.plate}</Table.Cell>
                    </Table.Row>
                );
            });
        return (
                rows
        )
    }

    render(){
        return ( <div>
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Modelo do Veículo</Table.HeaderCell>
                    <Table.HeaderCell>Cor</Table.HeaderCell>
                    <Table.HeaderCell>Placa</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {this.mountRows(this.props.vehicles)}
            </Table.Body>
        </Table>
            </div>
    )
    }
}

export  default Vehicles;