import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyAEtTr6VtoChssWOg_zRqN44aUUSnuE-v4",
    authDomain: "earnest-cosmos-175020.firebaseapp.com",
    databaseURL: "https://earnest-cosmos-175020.firebaseio.com",
    projectId: "earnest-cosmos-175020",
    storageBucket: "earnest-cosmos-175020.appspot.com",
    messagingSenderId: "812762203492",
    appId: "1:812762203492:web:390e190a4b655ee3f52671",
    measurementId: "G-FZ4STD7ZCB",
};

firebase.initializeApp(config);

export const myFirebase = firebase;
export const myFirestore = firebase.firestore();
export const myStorage = firebase.storage();
