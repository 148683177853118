import React, {Component} from 'react'
import ReactLoading from 'react-loading'
import {withRouter} from 'react-router-dom'
import {myFirebase} from '../../Config/MyFirebase'
import './Main.css'
import Board from '../ChatBoard/Board'
import {AppString} from './../Const'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop ,faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isOpenDialogConfirmLogout: false,
            currentPeerUser: null
        }
        this.currentUserId = localStorage.getItem(AppString.ID)
        this.currentUserAvatar = localStorage.getItem(AppString.PHOTO_URL)
        this.currentUserNickname =  localStorage.getItem(AppString.NICKNAME)
        this.listUser = []
    }

    componentDidMount() {
        this.checkLogin()
    }

    checkLogin = () => {
        if (!localStorage.getItem(AppString.ID)) {
            this.setState({isLoading: false}, () => {
                this.props.history.push('/')
            })
        } else {
            this.getListStation()
        }
    }

    getListStation = async () => {
        try {
            let user =  this.currentUserId;
            let localStations = await JSON.parse(sessionStorage.getItem("stations"));
            if(localStations == null) {
                let  check = JSON.stringify({"token": user});
                fetch(process.env.REACT_APP_BFF_API_URL  +'/event/checkStation', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: check
                }).then((response)=> {
                    const statusCode = response.status;
                    if(statusCode !== 200 ){
                        this.props.showToast(0, response.statusText)
                        this.cancelLogin()
                    }else{
                        response.json().then(
                            (obj)=>{
                                if( obj === null){
                                    this.props.showToast(2, "Desculpe! nenhum condominio que você possui autorização está configurado para acessar esse serviço");
                                    this.setState({isLoading: false})
                                    this.doLogout()
                                }else{
                                sessionStorage.setItem("stations",JSON.stringify(obj) );
                                sessionStorage.setItem("server_time",obj.server_time);
                                this.listUser = [...obj.stations]
                                this.setState({isLoading: false})
                                }
                            }
                        )
                    }
                });
            }else{
                this.listUser = [...localStations.stations];
                this.setState({isLoading: false})
            }


        }catch (e) {
            this.props.showToast(2, "Erro , Efetue Login Novamente!");
            this.setState({isLoading: false})
            this.doLogout()
        }

    }

    onLogoutClick = () => {
        this.setState({
            isOpenDialogConfirmLogout: true
        })
    }

    doLogout = () => {
        this.setState({isLoading: true})
        myFirebase
            .auth()
            .signOut()
            .then(() => {
                this.setState({isLoading: false}, () => {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.props.showToast(1, 'Logout success')
                    this.props.history.push('/')
                })
            })
            .catch(function (err) {
                this.setState({isLoading: false})
                // this.props.showToast(0, err.message)
                console.log(err.message);
            })
    }

    hideDialogConfirmLogout = () => {
        this.setState({
            isOpenDialogConfirmLogout: false
        })
    }

    onProfileClick = () => {
        this.props.history.push('/profile')
    }

    renderListUser = () => {
        if (this.listUser.length > 0) {
            let viewListUser = []
            this.listUser.forEach((item, index) => {

                viewListUser.push(
                    <button
                        key={index}
                        className={
                            this.state.currentPeerUser &&
                            this.state.currentPeerUser.id === item.id
                                ? 'viewWrapItemFocused'
                                : 'viewWrapItem'
                        }
                        onClick={() => {
                            this.setState({currentPeerUser: item})
                        }}
                    >               
                        <FontAwesomeIcon  className="viewAvatarItem" icon={faDesktop} />

                        <div className="viewWrapContentItem">
                <span className="textItem">{
                    item.name.toUpperCase()

                }
                      </span>
                            <span className="textItem1">
                                    {item.condo.name.toUpperCase() }

                        </span>
                        </div>
                    </button>
                )

            });


            return viewListUser
        } else {
            return null
        }
    }

    render() {
        return (
            <div className="root">
                {/* Header */}
                <div className="header-main">
                    <span>Eventos de Entrada e Saída</span>

                    <span className="icProfile" > {this.currentUserNickname}</span>
                    <FontAwesomeIcon className="icLogout" icon={faSignOutAlt} onClick={this.onLogoutClick}/>

                </div>

                {/* Body */}
                <div className="body">
                    <div className="viewListUser"> {this.renderListUser()}</div>
                    <div className="viewBoard">
                        {this.state.currentPeerUser ? (
                            <Board
                                currentPeerUser={this.state.currentPeerUser}
                                showToast={this.props.showToast}
                            />
                        ) :"" }
                    </div>
                </div>

                {/* Dialog confirm */}
                {this.state.isOpenDialogConfirmLogout ? (
                    <div className="viewCoverScreen">
                        {this.renderDialogConfirmLogout()}
                    </div>
                ) : null}

                {/* Loading */}
                {this.state.isLoading ? (
                    <div className="viewLoading">
                        <ReactLoading
                            type={'spin'}
                            color={'#ffffff'}
                            height={'3%'}
                            width={'3%'}
                        />
                    </div>
                ) : null}
            </div>
        )
    }

    renderDialogConfirmLogout = () => {
        return (
            <div>
                <div className="viewWrapTextDialogConfirmLogout">
                    <span className="titleDialogConfirmLogout">Deseja Sair ?</span>
                </div>
                <div className="viewWrapButtonDialogConfirmLogout">
                    <button className="btnYes" onClick={this.doLogout}>
                        SIM
                    </button>
                    <button className="btnNo" onClick={this.hideDialogConfirmLogout}>
                        CANCELAR
                    </button>
                </div>
            </div>
        )
    }
}

export default withRouter(Main)
