import React ,{Component} from "react";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import placeholder from '../../images/placeholder.png'


class Person extends Component {
   


    render() {
        let basePath = "https://fotos.condlink.com.br/documentos/";
        let path =  "";
        let photo = this.props.photo;

        if (photo && photo !== ""){
            path = basePath +"fotos_" + this.props.condo + "/" + this.props.photo;

    } else {
            path = placeholder;
        }

        return (
        
            <Card style={{width: 250 , maxHeight:300}}>
                <Image  style={{maxWidth: 230, maxHeight: 230}} src={path}  ui={false}/>

                <Card.Content>
                    <Card.Header>{this.props.name}</Card.Header>
                    <Card.Description>
                        {this.props.main ? "Principal" : this.props.type}
                    </Card.Description>
                </Card.Content>
            </Card>

        );
    }
}

    

export  default Person;