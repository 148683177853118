import React, {Component} from 'react'
import ReactLoading from 'react-loading'
import {withRouter} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {myFirebase, myFirestore} from '../../Config/MyFirebase'
import './Login.css'
import {AppString} from './../Const'
import 'firebase/database'
import {Button, Form} from "semantic-ui-react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    this.type = "";
    }

    handleUserChange(e){
        this.setState({username: e.target.value});
    }
    handlePassChange(e){
        this.setState({password: e.target.value});
    }

    componentDidMount() {
        this.checkLogin()
    }

    checkLogin = () => {
        if (localStorage.getItem(AppString.ID)) {
            this.setState({isLoading: false}, () => {
                this.setState({isLoading: false})
                this.props.showToast(1, 'Login success')
                this.props.history.push('/main')
            })
        } else {
            this.setState({isLoading: false})
        }
    };


    onLoginDirect = () => {
        this.setState({isLoading: true})
        let username = this.state.username;
        let email = username+"@condlink.com.br";
        let pass = this.state.password ;
        myFirebase
            .auth()
            .signInWithEmailAndPassword(email,pass)
            .then(async result => {
                this.saveLocalData(result, username);
            })
            .catch(err => {
                let msg = err.message;
                if(err.code === "auth/wrong-password"){
                        msg = "Senha ou usuario incorreto!"
                }else if( err.code ==="auth/too-many-requests"){
                    msg = "Você errou a senha muitas vezes seguidas ,  tente novamente mais tarde!!"
                }
                this.props.showToast(0, msg)
                this.setState({isLoading: false})
            })

    };



    saveLocalData = async (result , username) => {
        let user = result.user
        if (user) {
            const result = await myFirestore
                .collection(AppString.NODE_USERS)
                .where(AppString.ID, '==', user.uid)
                .get()


            if (result.docs.length === 0) {
                // Set new data since this is a new user
                myFirestore
                    .collection('users')
                    .doc(user.uid)
                    .set({
                        id: user.uid,
                        nickname: username,
                        username: this.state.username,
                        photoUrl: user.photoURL
                    })
                    .then(data => {

                        // Write user info to local
                        localStorage.setItem(AppString.ID, user.uid)
                        localStorage.setItem(AppString.NICKNAME,username)
                        localStorage.setItem(AppString.PHOTO_URL, user.photoURL)
                        this.setState({isLoading: false}, () => {
                            this.props.showToast(1, 'Login success')
                            this.props.history.push('/main')
                        })
                    })
            } else {
                // Write user info to local
                localStorage.setItem(AppString.ID, result.docs[0].data().id)
                localStorage.setItem(
                    AppString.NICKNAME,
                    username
                )
                localStorage.setItem(
                    AppString.PHOTO_URL,
                    result.docs[0].data().photoUrl
                )
                localStorage.setItem(
                    AppString.ABOUT_ME,
                    result.docs[0].data().aboutMe
                )


                this.setState({isLoading: false}, () => {
                    this.props.showToast(1, 'Login success')
                    this.props.history.push('/main')
                })
            }
        } else {
            this.props.showToast(0, 'User info not available')
        }
    };


    cancelLogin = () => {
        document.getElementById("form-login").reset();
    };

    handleSubmit = ( sub) => {


        (async (sub) => {
            let login = JSON.stringify({"username": this.state.username, "password": this.state.password});
            const rawResponse = await fetch(process.env.REACT_APP_BFF_API_URL +'/event/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: login
            });
            const content = await rawResponse.json();

            if(content.hasOwnProperty("error")){
                this.props.showToast(0, content.error)
                this.cancelLogin()
            }else if (content.hasOwnProperty("auth")){
                console.log(content)
                sessionStorage.setItem(AppString.NICKNAME, this.state.username)
                sessionStorage.setItem("server_time", content.server_time)
                this.onLoginDirect();
            }
        })();

    };


    render() {
        return (
            <div className="viewRoot">
                <div className="header-main">Fotos</div>
                <Container style={{paddingTop: 150  , width: 500, height: 500}}>
                    <Form onSubmit={this.handleSubmit} id="form-login">
                        <Form.Field>
                            <label>Usuário</label>
                            <input onChange={this.handleUserChange.bind(this)} name="username" id="username" placeholder='Usuário' />
                        </Form.Field>
                        <Form.Field>
                            <label>Senha</label>
                            <input   onChange={this.handlePassChange.bind(this)} name="password" id="password" type="password" placeholder='*******' />
                        </Form.Field>

                        <Button type='submit'>Login</Button>
                    </Form>
                </Container>
                {this.state.isLoading ? (
                    <div className="viewLoading">
                        <ReactLoading
                            type={'spin'}
                            color={'#ffffff'}
                            height={'3%'}
                            width={'3%'}
                        />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default withRouter(Login)
