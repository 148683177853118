import React, {Component} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {myFirestore} from '../../Config/MyFirebase'
import './Board.css'
import {AppString} from './../Const'
import People from "../People/People";
import Vehicles from "../Vehicles/Vehicles";
import { Message } from 'semantic-ui-react'
import newId from '../Utils/NewId';

export default class Board extends Component {


    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isShowSticker: false,
            inputValue: '',
            countEvent:0
        }
        this.currentUserId = localStorage.getItem(AppString.ID);
        this.currentUserAvatar = localStorage.getItem(AppString.PHOTO_URL);
        this.currentUserNickname = localStorage.getItem(AppString.NICKNAME);
        this.listMessage = [];
        this.listEvent =[];

            this.currentPeerUser = this.props.currentPeerUser;
        this.removeListener = [];

    }

    firestoreEvent() {
        this.setState(prevState => ({
            countEvent: prevState.countEvent + 1
        }));
    }

    componentDidMount() {
        this.id = newId();
        // For first render, it's not go through componentWillReceiveProps
        this.getListHistory()
    }

    componentWillReceiveProps(nextProps) {
        this.setState(prevState => ({
            countEvent: 0
        }));
        this.listEvent =[];
    }

    componentWillUnmount() {
        if (this.removeListener.length > 0) {
            this.removeListener = []
        }
    }

    componentDidUpdate(newProps) {
       if (newProps.currentPeerUser) {
            this.currentPeerUser = newProps.currentPeerUser
            // this.getListHistory()
        }
    }

    getListHistory = async () => {

        if (this.removeListener.length > 0) {
            this.removeListener = []
        }
        this.listMessage.length = 0
        this.listEvent.length  = 0 ;
        if (
            this.hashString(this.currentUserId) <=
            this.hashString(this.currentPeerUser.id)
        )
            console.log("");
            let load = false ;
            let routes = await this.currentPeerUser.routes;

            this.removeListener.push(

                routes.forEach((route, i)=> {
                    myFirestore
                        .collection("condos")
                        .doc(this.currentPeerUser.condo.id.toString())
                        .collection(route.toString())
                        // .where("create_at",">" , time)
                        .orderBy("create_at","desc")
                        .limit(1)
                        .onSnapshot(
                            snapshot => {
                                snapshot.docChanges().forEach(change => {
                                    if (change.type === AppString.DOC_ADDED ) {
                                        if(!snapshot.metadata.fromCache) {
                                            if(load) {
                                            this.listEvent = change.doc.data()
                                            this.firestoreEvent()
                                            }else{
                                                load = true
                                            }
                                        }
                                    }
                                });
                            },
                            err => {
                                console.log("error:" + err.toString());
                                // this.props.showToast(0, err.toString())
                                this.setState({
                                    isLoading: false,
                                })
                            },
                        )
                })

    )

    };




    render() {
        return (
            <div className="viewChatBoard">
                {/* Header */}
                <div className="headerChatBoard">

                    { this.listEvent.terminal ? this.renderTerminalHeader() : ""}

                    { this.listEvent.homes ?this.renderHomeHeader() : ""}



                    <span className="textHeaderChatBoard">

          </span>
                </div>

                {/* List message */}
                <div className="viewListContentChat">


                    {this.renderListMessage()}


                    {this.listEvent.vehicles ? this.renderVehicle() :""}

                </div>
                <div/>
            </div>
        )
    }

    renderHomeHeader = () => {
        let viewListMessage = [];
        try {
            let homes = JSON.parse(this.listEvent.homes);
            viewListMessage.push(
                homes
            );
            if(homes.length > 0 )
            return (  <Message style={ {margin: 10} } key={this.id}>
                <Message.Header>Unidades:</Message.Header>
                <p> { viewListMessage}
                </p>
            </Message>)
        }catch (e) {
        }
    };

    renderTerminalHeader = () => {
        try {
                return (  <Message style={ {margin: 10} } key={this.id + "T"}>
                    <Message.Header>Terminal:</Message.Header>
                    <p> { this.listEvent.terminal}
                    </p>
                </Message>)
        }catch (e) {
        }
    };

    renderVehicle= () => {

        let viewListMessage = [];
        try {
            let v = JSON.parse(this.listEvent.vehicles);
            if(v.length > 0)
            viewListMessage.push(
                <Vehicles  key={"ve"+this.id} vehicles={v}/>
            );
        }catch (e) {

        }

        return viewListMessage
    };

    renderListMessage = () => {

        let viewListMessage = [];
        try {
            let p = JSON.parse(this.listEvent.people);

            viewListMessage.push(
                <People  key={"pe"+this.id} panic={this.listEvent.panic} people={p}  />
            );
        }catch (e) {

        }
        return viewListMessage
    }


    hashString = str => {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
            hash += Math.pow(str.charCodeAt(i) * 31, str.length - i)
            hash = hash & hash // Convert to 32bit integer
        }
        return hash
    }

}
