import React ,{Component} from "react";

import { Card } from 'semantic-ui-react'
import Person from "./Person";


class People extends Component {


    mountCardGroup( people) {
        let cards = []

         people.forEach((item, i) => {
             cards.push( <Person key={i} name={item.name}  gender={item.gender} type={item.type} main={item.main} condo={item.condo} photo={item.photo} />);
         });
     return (

         cards
     )
 }

    render() {
        let cards = this.mountCardGroup(this.props.people)
        return (

            <div>
                {this.props.panic ? (
                    <div className={"panic"}>
                        <h1 className={"fontWhite"}> ATENÇÃO EVENTO DE  PÂNICO !!!!!! </h1>
                        <Card.Group >
                            {cards}
                        </Card.Group>
                    </div>
                ) : (
                    <div>
                        <Card.Group >
                            {cards}
                        </Card.Group>
                    </div>
                )}
            </div>
        );

    }
}
    export default People;


